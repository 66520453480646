import React from 'react';
import '../styles/Projects.css';

function Projects() {
  return (
    <section id="projects" className="projects">
      <h2>Projetos</h2>
      <p>Lista de projetos de Matheus Henrique Caiser.</p>
    </section>
  );
}

export default Projects;